<template>
  <div class="hero login-hero is-fullheight is-app-grey">
    <div style="display:contents; margin: auto">
      <img src="images/yield_spreads.png" alt="Banner"/>
    </div>
  </div>
</template>
<script>



export default {
  components: {
  },
  data() {
    return {
      gridColumns: ['Strategy', 'Mid', 'Change', '3PM Close'],
      gridData: Array,
      timestampData: 'Loading...'
    }
  },
  computed: {
  },
  mounted() {

  },
  beforeUnmount() {
  },
};
</script>

